<template>
  <v-app>
    <v-main class="pa-0">
      <router-view />
      <GlobalSnack />
      <v-btn
        v-if="settings.show_cookie_consent && $route.path === '/'"
        text
        small
        color="secondary"
        id="open_preferences_center"
        class="text-none mr-2 cookie-prefs-btn"
      >
        Update cookies preferences
      </v-btn>
    </v-main>
  </v-app>
</template>

<script>
import GlobalSnack from "@/components/common/global-snack.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  components: { GlobalSnack },
  data: () => ({}),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
      listing: (state) => state.listingsModule.currentListing,
    }),
  },
  mounted() {
    this.initSession();
  },
  watch: {
    listing(val) {
      this.setMetaTags(val);
    },
    settings: {
      handler() {
        this.initializeCookieConsent();
        this.setMetaTags({
          nickname: this.settings.sections.header.title,
          title: this.settings.sections.header.sub_title,
          picture: this.settings.bg_image,
        });

        // Initialize Google Tag Manager
        const gtmId = this.settings.analytics?.gtm;
        if (gtmId) {
          // Add GTM script to head
          const gtmScript = document.createElement("script");
          gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,"script","dataLayer","${gtmId}");`;
          document.head.insertBefore(gtmScript, document.head.firstChild);

          // Add GTM noscript iframe to body
          const noscript = document.createElement("noscript");
          const iframe = document.createElement("iframe");
          iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
          iframe.height = "0";
          iframe.width = "0";
          iframe.style.display = "none";
          iframe.style.visibility = "hidden";
          noscript.appendChild(iframe);
          document.body.insertBefore(noscript, document.body.firstChild);
        }

        // Initialize Google Analytics
        const tag = this.settings.analytics.google;
        if (tag) {
          const tagManagerScript = document.createElement("script");
          const url = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
          tagManagerScript.setAttribute("src", url);
          document.head.appendChild(tagManagerScript);

          window.dataLayer = window.dataLayer || [];
          window.gtag = function () {
            window.dataLayer.push(arguments);
          };
          window.gtag("js", new Date());
          window.gtag("config", tag);
        }

        // Initialize Facebook Pixel
        const pixelId = this.settings.analytics.pixel;
        if (pixelId) {
          this.initializeFacebookPixel(pixelId);
        }

        // Inject custom scripts
        const script = document.createElement("script");
        script.innerHTML = this.settings.injected_scripts;
        document.head.appendChild(script);
      },
    },
  },
  methods: {
    ...mapActions("analyticsModule", ["initSession"]),
    setMetaTags({ nickname, title, picture }) {
      const image = picture.replace(
        "upload/",
        "upload/w_600,h_336,c_fill,q_auto,f_auto/"
      );
      this.updateMetaTag("property", "og:title", nickname);
      this.updateMetaTag("property", "og:description", title);
      this.updateMetaTag("property", "og:image", image);
      this.updateMetaTag("property", "og:image:alt", "property image");
      this.updateMetaTag("property", "og:url", window.location.href);
      this.updateMetaTag("property", "og:type", "website");

      // Add Facebook Pixel meta tag if configured
      const pixelId = this.settings?.analytics?.pixel;
      if (pixelId) {
        this.updateMetaTag("facebook-domain-verification", "", pixelId);
      }
    },
    updateMetaTag(attrName, attrValue, content) {
      let element = document.querySelector(`meta[${attrName}="${attrValue}"]`);
      if (!element) {
        element = document.createElement("meta");
        element.setAttribute(attrName, attrValue);
        document.head.appendChild(element);
      }
      element.setAttribute("content", content);
    },
    initializeFacebookPixel(pixelId) {
      /* eslint-disable */
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", pixelId);
      window.fbq("track", "PageView");
      /* eslint-enable */
    },
    initializeCookieConsent() {
      if (!this.settings.show_cookie_consent) return;

      // Add cookie consent script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://www.termsfeed.com/public/cookie-consent/4.2.0/cookie-consent.js";
      script.charset = "UTF-8";
      script.onload = () => {
        /* eslint-disable no-undef */
        cookieconsent.run({
          notice_banner_type: "simple",
          consent_type: "express",
          palette: "light",
          language: "en",
          page_load_consent_levels: ["strictly-necessary"],
          notice_banner_reject_button_hide: true,
          preferences_center_close_button_hide: false,
          page_refresh_confirmation_buttons: false,
          website_name: "Boom",
          website_privacy_policy_url: "https://www.boomnow.com/privacy-policy",
        });
        /* eslint-enable no-undef */
      };

      document.body.insertBefore(script, document.body.firstChild);

      // Add noscript element
      const noscript = document.createElement("noscript");
      noscript.innerHTML =
        'Free cookie consent management tool by <a href="https://www.termsfeed.com/" rel="nofollow">TermsFeed</a>';
      document.body.insertBefore(
        noscript,
        document.body.firstChild.nextSibling
      );
    },
  },
};
</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@each $position in relative, absolute, fixed
  .p-#{$position}
    position: $position

.font-weight-semibold
  font-weight: 600 !important

.theme--light.v-divider
  border-color: map-get($grey, "lighten-3") !important
</style>
