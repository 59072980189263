import { LISTINGS, LISTING, CHECKOUT_SESSION } from "@/consts/ROUTES";
import { Listing } from "@/models/listing.model";
import { axios } from "./fetch";
import isEmpty from "lodash/isEmpty";

export default {
  namespaced: true,
  state: () => ({
    listings: [],
    pagination: {},
    currentListing: {},
    reviews: {},
    currentPricing: null,
    loading: false,
    pricingLoading: false,
  }),
  mutations: {
    setListings(state: any, payload: Listing[]) {
      state.listings = payload;
    },
    appendListings(state: any, payload: Listing[]) {
      state.listings = [...state.listings, ...payload];
    },
    setListingsPagination(state: any, payload: any) {
      state.pagination = payload;
    },
    setCurrentListing(state: any, payload) {
      if (payload.listing) state.currentListing = payload.listing;
      if (payload.reviews) state.reviews = payload.reviews;
    },
    setCurrentPricing(state: any, payload) {
      if (!payload || isEmpty(payload)) {
        state.currentPricing = null;
        return;
      }

      const pricingData = typeof payload === "object" ? payload : {};
      if (pricingData.rate_plans && pricingData.rate_plans_pricings) {
        state.currentPricing = {
          ...pricingData,
          taxes_n_fees: 0,
        };
        return;
      }

      const fees_total = pricingData.taxes_breakdown
        .filter((t) => t && t.base_type === "FEE" && !t.vat_inclusive)
        .reduce((acc, t) => acc + (Number(t.amount) || 0), 0);

      const taxes_total = pricingData.taxes_breakdown
        .filter((t) => t && t.base_type === "TAX" && !t.vat_inclusive)
        .reduce((acc, t) => acc + (Number(t.amount) || 0), 0);

      state.currentPricing = {
        taxes_n_fees: taxes_total + fees_total,
        ...pricingData,
      };
    },
    setIsLoading(state: any, payload: boolean) {
      state.loading = payload;
    },
    setPricingLoading(state: any, payload: boolean) {
      state.pricingLoading = payload;
    },
  },
  actions: {
    async getListings({ commit }: any, params: any) {
      commit("setIsLoading", true);
      await axios.get(LISTINGS, { params }).then(({ data }) => {
        if (params.append) {
          commit("appendListings", data.listings as Listing[]);
        } else {
          commit("setListings", data.listings as Listing[]);
        }
        commit("setIsLoading", false);
        commit("setListingsPagination", data.pagi_info);
      });
    },
    getListing({ commit }: any, id: number) {
      commit("setIsLoading", true);
      axios.get(`${LISTING}/${id}`).then(({ data }) => {
        commit("setCurrentListing", data as Listing);
        commit("setIsLoading", false);
      });
    },
    updateListing({ commit }: any, payload: { index: number; item: Listing }) {
      const data = JSON.stringify(payload);
      axios.put(LISTINGS, data).then(({ data }) => {
        commit("setListings", data as Listing[]);
      });
    },
    async pricingInfo({ commit }, { id, params }) {
      commit("setPricingLoading", true);
      try {
        const { data } = await axios.get(`${LISTINGS}/${id}/pricing`, {
          params,
        });
        commit("setCurrentPricing", data.info);
      } finally {
        commit("setPricingLoading", false);
      }
    },
    checkoutSession(_: any, data) {
      return axios.post(`${CHECKOUT_SESSION}`, data);
    },
  },
};
