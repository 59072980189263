<template>
  <v-sheet>
    <div v-if="easy">
      <v-img
        :class="{
          'rounded-lg': !tile,
          'rounded-r-0': isHotelSite,
        }"
        style="cursor: pointer"
        :src="item"
        :lazy-src="whiteImg"
        :aspect-ratio="isMobile ? 1.37 : 0"
        :height="itemHeight"
        eager
        fade
        max-height="100vh"
        :contain="easyContain"
      />
    </div>
    <v-row v-else no-gutters>
      <v-col cols="12" sm="4">
        <v-img
          :class="`rounded-${isMobile ? 't' : 'l'}-lg`"
          :src="item.image || image(IMAGE_PLACEHOLDER)"
          :aspect-ratio="isMobile ? 1.34 : 0.93"
          height="100%"
        />
      </v-col>
      <v-col cols="12" sm="8">
        <div class="d-flex justify-center align-center fill-height px-4 py-12">
          <v-sheet :max-width="isMobile ? '100%' : 600" width="100%">
            <v-rating
              class="mb-5 ml-n2"
              color="warning"
              background-color="warning"
              :value="item.rating"
              readonly
            />
            <div
              class="text-xl-body-1 mb-8 font-weight-medium line-clamp"
              v-html="item.body"
            />
            <div
              class="text-body-2 font-font-weight-medium grey--text d-flex justify-end"
            >
              <div>
                {{ item.date }}
                <span class="text-caption">({{ item.name }})</span>
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { IMAGE_PLACEHOLDER } from "@/consts/CMS";
import MediaMixin from "@/mixins/media-mixin";
import DeviceMixin from "@/mixins/device-mixin";
import whiteImg from "@/assets/images/white.png";
import { mapState } from "vuex";
export default {
  name: "SiteCarouselItem",
  mixins: [MediaMixin, DeviceMixin],
  props: {
    item: [Object, String],
    easy: {
      type: Boolean,
      default: false,
    },
    easyContain: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    listingId: {
      type: [Number, String],
      default: null,
    },
    itemHeight: {
      type: String,
      default: null,
    },
  },
  data: () => ({ IMAGE_PLACEHOLDER, whiteImg }),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    isHotelSite() {
      return this.settings.site_type === "hotel" && !this.isMobile;
    },
  },
  methods: {
    onClick(item) {
      console.log(item);
    },
  },
};
</script>

<style scoped>
.line-clamp {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 8;
}
</style>
