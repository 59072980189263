var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"listings-item",class:{ 'mb-3': !_vm.horizontal },attrs:{"elevation":"0","outlined":_vm.horizontal,"rounded":"lg"},on:{"click":_vm.listingClick}},[_c('div',{staticClass:"p-relative"},[_c('v-toolbar',{staticClass:"pt-3",attrs:{"color":"transparent","width":"100%","absolute":"","dense":"","flat":""}},[_c('v-spacer')],1)],1),_c('v-row',{class:!_vm.horizontal ? 'flex-column' : ''},[_c('v-col',{attrs:{"cols":"12","lg":_vm.carouselLg}},[_c('site-carousel',{class:{ 'listing-placeholder': !_vm.thumbnails[0] },attrs:{"items":_vm.thumbnails[0] ? _vm.thumbnails : [_vm.PLACEHOLDER],"listing-id":_vm.item.id,"slide":_vm.slideIndex,"easy":"","item-height":_vm.horizontal ? '200px' : '250px'},on:{"update:slide":function($event){_vm.slideIndex=$event}}})],1),_c('v-col',{class:{ 'pt-0': !_vm.horizontal, 'my-2 me-2': _vm.horizontal }},[_c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-card-title',{class:['mb-2 pa-0 flex-column align-start'],staticStyle:{"font-size":"1rem","font-weight":"600","line-height":"1.3rem"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ellipsis-1"},[_vm._v(_vm._s(_vm.item.title))]),(_vm.item.airbnb_score)?_c('span',{staticClass:"text-caption pre d-flex align-center"},[_c('v-icon',{attrs:{"size":"16","color":"yellow darken-2"}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.item.airbnb_score)+" ")],1):_vm._e()]),_c('div',{staticClass:"font-weight-regular text-body-2 my-1"},[_vm._v(" "+_vm._s(_vm.location)+" ")]),_c('div',{class:[
              'text-caption text--darken-2 w-full d-inline-flex align-center',
              { 'justify-space-between': !_vm.horizontal },
            ]},[_c('div',{staticClass:"d-flex text-body-2 mt-0"},[_c('span',[_vm._v(_vm._s(_vm.listingInfo.guests.count)+" Guests")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.listingInfo.beds.count)+" Bedrooms")]),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.listingInfo.bathrooms.count)+" Bathrooms")])])]),(_vm.horizontal && _vm.item.marketing_content?.summary)?_c('div',{staticClass:"text-caption text--secondary mt-3 listing-description"},[_vm._v(" "+_vm._s(_vm.item.marketing_content.summary)+" ")]):_vm._e()]),_c('div',{staticClass:"mt-auto"},[(_vm.item.extra_info.current_price)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-semibold",domProps:{"textContent":_vm._s(
                _vm.toMoney(
                  _vm.item.extra_info.current_price.our_price,
                  0,
                  _vm.currencySign
                )
              )}}),(
                _vm.item.extra_info.current_price.total_price !==
                _vm.item.extra_info.current_price.our_price
              )?_c('span',{staticClass:"ml-1"},[_vm._v("+ Fees")]):_vm._e(),_c('v-icon',{staticClass:"mx-3",attrs:{"size":"3"}},[_vm._v("fas fa-circle")]),_c('span',{staticClass:"nights-amount text-caption text--secondary font-weight-medium"},[_vm._v(_vm._s(_vm.days_count)+" nights")])],1):_vm._e()])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }