import mixins from "vue-typed-mixins";
import DeviceMixin from "@/mixins/device-mixin";
import { mapState } from "vuex";

export default mixins(DeviceMixin).extend({
  computed: {
    ...mapState({
      settings: (state: any) => state.settingsModule.settings,
    }),
    siteHeaderHeight(): string {
      return this.isMobile ? "60px" : "75px";
    },
    siteExtendedHeaderHeight(): string {
      return this.isMobile ? "60px" : "80px"; // 108
    },
    logoWidth(): string {
      return this.isMobile ? "60px" : "100px";
    },
    listingFiltersWidth(): string {
      return this.isMobile ? "260px" : this.isIPad ? "320px" : "580px";
    },
    actionsColor(): string {
      return this.settings.theme?.actions_color;
    },
    primaryColor(): string {
      return this.settings.theme?.main_color;
    },
    secondaryColor(): string {
      return this.settings.theme?.secondary_color;
    },
  },
});
