import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/home-view.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/listings",
    name: "listings",
    component: () =>
      import(/* webpackChunkName: "listings" */ "@/views/listings-view.vue"),
  },
  {
    path: "/listing/:id",
    name: "listing",
    component: () =>
      import(/* webpackChunkName: "listing" */ "@/views/listing-view.vue"),
  },
  {
    path: "/map",
    name: "map",
    component: () =>
      import(/* webpackChunkName: "map" */ "@/views/site-map.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "success" */ "@/views/success-view.vue"),
  },
  {
    path: "/cancel",
    name: "cancel",
    component: () =>
      import(/* webpackChunkName: "success" */ "@/views/cancel-view.vue"),
  },
  {
    path: "/inquiry-success",
    name: "inquiry-success",
    component: () =>
      import(
        /* webpackChunkName: "inquiry-success" */ "@/views/inquiry-success-view.vue"
      ),
  },
  {
    path: "/search-widget",
    name: "search-widget",
    component: () =>
      import(
        /* webpackChunkName: "search-widget" */ "@/views/search-widget-view.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
