<template>
  <div v-if="!isAgentSite" class="site-header">
    <v-navigation-drawer
      v-if="isMobile"
      v-model="drawer"
      app
      right
      hide-overlay
    >
      <v-list>
        <v-list-item @click="listingsSearch">
          <v-list-item-icon>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="settings?.business?.phone"
          :href="`tel:${settings.business.phone}`"
        >
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Call Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="settings?.show_whatsapp_link && settings?.business?.phone"
          :href="`https://wa.me/${settings.business.phone.replace(/\D/g, '')}`"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-whatsapp</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="settings?.social_links?.about_us"
          :href="settings.social_links.about_us"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :flat="flat"
      :dark="dark"
      :color="dark ? 'transparent' : 'white'"
      class="site-header-bar"
      :height="siteHeaderHeight"
    >
      <router-link class="header-router-link" to="/">
        <img
          v-if="!dark"
          :src="settings.logo"
          :width="logoWidth"
          class="logo-img pa-2 ps-0"
        />
      </router-link>
      <v-spacer />
      <v-sheet v-if="!isMobile" color="transparent" class="pa-0">
        <v-btn @click="listingsSearch" text>
          <span class="text-capitalize ml-2">Search</span>
        </v-btn>
        <v-btn href="/" text>
          <span class="text-capitalize ml-2">Home</span>
        </v-btn>
        <v-btn
          v-if="settings?.business?.phone"
          :href="`tel:${settings.business.phone}`"
          text
        >
          <span class="text-capitalize ml-2">Call Us</span>
        </v-btn>
        <v-btn
          v-if="settings?.show_whatsapp_link && settings?.business?.phone"
          :href="`https://wa.me/${settings.business.phone.replace(/\D/g, '')}`"
          target="_blank"
          text
        >
          <span class="text-capitalize ml-2">Contact Us</span>
        </v-btn>
        <v-btn
          v-if="settings?.social_links?.about_us"
          :href="settings.social_links.about_us"
          target="_blank"
          text
        >
          <span class="text-capitalize ml-2">About Us</span>
        </v-btn>
      </v-sheet>
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="drawer = !drawer"
        class="ms-2 fixed-nav-icon"
      />
    </v-app-bar>
  </div>
</template>

<script>
import { LOGO, LOGO_WHITE } from "@/consts/CMS";
import DeviceMixin from "@/mixins/device-mixin";
import MediaMixin from "@/mixins/media-mixin";
import StylesMixin from "@/mixins/styles-mixin";
import { mapState } from "vuex";

export default {
  name: "site-header",
  components: {},
  mixins: [DeviceMixin, MediaMixin, StylesMixin],
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    isAgentSite() {
      return this.settings.site_type === "agent";
    },
    portalUrl() {
      return this.settings.portal_url;
    },
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    LOGO,
    LOGO_WHITE,
    drawer: false,
  }),
  methods: {
    listingsSearch() {
      this.$router.push({
        name: "listings",
        query: this.$route.query,
      });
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.site-header-bar {
  &.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 1px 1px 0px map-get($grey, "lighten-3");
  }
}
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
.logo-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.header-router-link {
  height: 100%;
}
</style>
