import Vue from "vue";
import Vuex from "vuex";
import propertiesModule from "@/store/properties.module";
import listingsModule from "@/store/listings.module";
import destinationsModule from "@/store/destinations.module";
import usersModule from "@/store/users.module";
import featuresModule from "@/store/features.module";
import reviewsModule from "@/store/reviews.module";
import settingsModule from "@/store/settings.module";
import analyticsModule from "@/store/analytics.module";
import { findIndex } from "lodash";

Vue.use(Vuex);

interface Snack {
  snackId: string;
  message: string;
  color: string;
  timeout: number;
}

export default new Vuex.Store<{ snackbars: Snack[] }>({
  state: {
    snackbars: [],
  },
  getters: {
    snackbars: (state) => state.snackbars,
  },
  mutations: {
    addSnack(state, snack: Snack) {
      state.snackbars.push(snack);
    },
    removeSnack(state, snackId) {
      const snackIndex = findIndex(
        state.snackbars,
        (snack) => snack.snackId === snackId
      );
      if (snackIndex !== -1) {
        state.snackbars.splice(snackIndex, 1);
      }
    },
  },
  actions: {},
  modules: {
    propertiesModule,
    listingsModule,
    destinationsModule,
    usersModule,
    featuresModule,
    reviewsModule,
    settingsModule,
    analyticsModule,
  },
});
