import axios from "axios";
// axios.defaults.baseURL = `https://staging.api.designedvr.com/api/booking`;
axios.defaults.baseURL = `/api/booking`;

// Add request interceptor to include booking_session_id
axios.interceptors.request.use((config) => {
  const bookingSessionId = localStorage.getItem("booking_session_id");

  if (bookingSessionId) {
    if (config.method?.toLowerCase() === "get") {
      config.params = {
        ...config.params,
        booking_session_id: bookingSessionId,
      };
    } else {
      config.data = {
        ...config.data,
        booking_session_id: bookingSessionId,
      };
    }
  }
  return config;
});

export { axios };
