export default {
  namespaced: true,

  state: {
    sessionId: null,
  },

  mutations: {
    setSessionId(state, id) {
      state.sessionId = id;
    },
  },

  actions: {
    async initSession({ commit }) {
      const sessionId =
        localStorage.getItem("booking_session_id") ||
        Math.random().toString(36).substring(2, 15);
      localStorage.setItem("booking_session_id", sessionId);
      commit("setSessionId", sessionId);
      return sessionId;
    },

    clearSession({ commit }) {
      localStorage.removeItem("booking_session_id");
      commit("setSessionId", null);
    },
  },

  getters: {
    getSessionId: (state) => state.sessionId,
  },
};
